import * as React from 'react';
import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Error404 } from './features/chrome/Error404';

import { QueryClientProvider, queryClient } from './lib/query';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme, lightTheme, ThemeType, ThemeSelectorContext } from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { AuthBackendProvider, AuthProvider } from './api/firebase';
import { SuspenseLoadingPage } from './features/chrome/Page';
import { SuspenseStandalonePage } from './features/chrome/StandalonePage';
import { useDeferredStatsSubmission } from './features/coach/lib/stats';
import { useLottieAnimation } from './api/lottie';
import { usePageviewLogging } from './lib/logging';
import ReactGA from 'react-ga4';
import { LEGACY_ROUTES } from './lib/LegacyRoutes';
import { JoyrideProvider } from './features/tour/joyride';

const MEASUREMENT_ID = import.meta.env.VITE_WEB_MEASUREMENT_ID;
ReactGA.initialize(MEASUREMENT_ID, {
  gaOptions: {
    debug_mode: true,
  },
  gtagOptions: {
    debug_mode: true,
  },
});

const PreloadFiles: React.FC = () => {
  useLottieAnimation('skeletalAnimation');
  return <></>;
};

export default function App() {
  const [selectedTheme, setTheme] = useState<ThemeType>(ThemeType.Dark);

  const currentTheme =
    selectedTheme === ThemeType.Light ? lightTheme : darkTheme;

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AuthBackendProvider>
            <ThemeSelectorContext.Provider value={[selectedTheme, setTheme]}>
              <ThemeProvider theme={currentTheme}>
                <JoyrideProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DefferedSubmissions />
                    <Routes>
                      <Route path="ui-demo" element={<LazyUIDemoPage />} />
                      {/* Navigation Routes */}
                      <Route path="/sessions">
                        <Route index element={<LazySessionViewPage />} />
                        <Route
                          path=":appointmentId"
                          element={<LazySessionViewPage />}
                        />
                        <Route
                          path=":appointmentId/history"
                          element={<LazySessionHistoryPage />}
                        >
                          <Route
                            index
                            element={<LazySessionHistoryExerciseReplayOutlet />}
                          />
                          <Route
                            path="replay"
                            element={<LazySessionHistoryExerciseReplayOutlet />}
                          />
                          <Route
                            path="screenshots"
                            element={<LazySessionHistoryScreenshotsOutlet />}
                          />
                          <Route
                            path="rom"
                            element={<LazySessionHistoryRomOutlet />}
                          />
                        </Route>
                      </Route>
                      <Route
                        path="/sessions/:appointmentId/history/rom-embed"
                        element={<LazyRomReportWebviewPage />}
                      />
                      <Route
                        path="/sessions/:appointmentId/summary-embed"
                        element={<LazyAppointmentSummaryWebviewPage />}
                      />

                      <Route path="telehealth">
                        <Route
                          path=":appointmentId/join"
                          element={<LazyAppointmentLobbyPage />}
                        />
                        <Route
                          path="join"
                          element={<LazyAppointmentTokenPage />}
                        />
                      </Route>

                      <Route path="/plans">
                        <Route index element={<LazyPlanViewPage />} />
                        <Route
                          path=":prescriptionId"
                          element={<LazyPlanViewPage />}
                        >
                          <Route
                            index
                            element={<LazyPrescriptionExerciseListOutlet />}
                          />
                          <Route
                            path="exercise-list"
                            element={<LazyPrescriptionExerciseListOutlet />}
                          />
                          <Route
                            path="reports"
                            element={<LazyPlanReportList />}
                          />
                        </Route>
                        <Route
                          path=":prescriptionId/exercise"
                          element={<LazyPrescriptionSessionPage />}
                        />
                        <Route
                          path=":prescriptionId/intro"
                          element={<LazyHEPIntroPage />}
                        />
                        <Route
                          path=":prescriptionId/video"
                          element={<LazyHEPSetupVideoPage />}
                        />
                        <Route
                          path=":prescriptionId/setup"
                          element={<LazyHEPSetupPage />}
                        />
                      </Route>
                      <Route path="/progress">
                        <Route
                          path=":patientId"
                          element={<LazyHEPDashboardPage />}
                        />
                        <Route
                          path=":patientId/webview"
                          element={<LazyHEPDashboardWebviewPage />}
                        />
                      </Route>

                      <Route path="/settings">
                        <Route index element={<LazySettingsPage />} />
                        <Route path="profile" element={<LazySettingsPage />} />
                      </Route>

                      <Route path="/help" element={<LazyHelpPage />} />

                      <Route
                        path="/patients/:patientId/plans/:prescriptionId"
                        element={<LazyPatientPlanViewPage />}
                      />
                      <Route
                        path="/patients/:patientId/sessions/:appointmentId"
                        element={<LazyPatientSessionViewPage />}
                      />
                      <Route path="/patients" element={<LazyPatientsViewPage />}>
                        <Route path=":patientId">
                          <Route index element={<LazyPatientActivityOutlet />} />
                          <Route
                            path="sessions"
                            element={<LazyPatientSessionsOutlet />}
                          />

                          <Route
                            path="progress"
                            element={<LazyPatientProgressOutlet />}
                          />
                          <Route
                            path="plans"
                            element={<LazyPatientPlansOutlet />}
                          />
                          <Route
                            path="activity"
                            element={<LazyPatientActivityOutlet />}
                          />
                          <Route
                            path="forms"
                            element={<LazyPatientFormsOutlet />}
                          />
                          <Route
                            path="communication"
                            element={<LazyPatientCommunicationOutlet />}
                          />
                          <Route
                            path="replay"
                            element={<LazyPatientPlanReplayOutlet />}
                          />
                        </Route>
                      </Route>

                      <Route
                        path="/communication"
                        element={<LazyCommunicationPage />}
                      />

                      <Route path="/triage">
                        <Route index element={<LazyTriagePage />} />
                        <Route path="form" element={<LazyTriageFormPage />} />
                        <Route path="end" element={<LazyTriageFormEndPage />} />
                        <Route
                          path="recommendation"
                          element={<LazyTriageRecommendationPage />}
                        />
                        <Route path="calendly" element={<LazyCalendlyPage />} />
                      </Route>

                      <Route path="/monitoring" element={<LazyMonitoringPage />}>
                        <Route index element={<LazyRtmCPTCodes />} />
                        <Route path="codes" element={<LazyRtmCPTCodes />} />
                        <Route
                          path="patients"
                          element={<LazyRtmMonitoredPatients />}
                        />
                      </Route>

                      <Route
                        path="/"
                        element={<Navigate to="/sessions" replace />}
                      />
                      <Route path="/auth" element={<LazyAuthPage />} />
                      <Route path="/signup" element={<LazySignupPage />} />
                      <Route path="/schedule" element={<LazySchedulePage />} />

                      {/* Admin */}
                      <Route
                        path="/model-admin"
                        element={<LazyCreateModelPage />}
                      />
                      <Route
                        path="/admin/appointment"
                        element={<LazyAdminAppointmentPage />}
                      />
                      <Route
                        path="/admin/calendly"
                        element={<LazyCalendlySyncPage />}
                      />

                      {/* Forms */}
                      <Route path="/form/:formId" element={<LazyFormPage />} />
                      <Route
                        path="/form/webview/:formId"
                        element={<LazyFormWebviewPage />}
                      />
                      <Route path="/form" element={<LazyConsentTokenPage />} />

                      <Route path="*" element={<Error404 />} />

                      {/* Self Test */}
                      <Route path="/self-test" element={<LazySelfTestPage />}>
                        <Route
                          index
                          element={<LazySelfTestExerciseListOutlet />}
                        />
                        <Route
                          path="exercises"
                          element={<LazySelfTestExerciseListOutlet />}
                        />
                        <Route
                          path="reports"
                          element={<LazySelfTestReportList />}
                        />
                      </Route>
                      <Route
                        path="/self-test/intro"
                        element={<LazySelfTestIntroPage />}
                      />
                      <Route
                        path="/self-test/video"
                        element={<LazySelfTestSetupVideoPage />}
                      />
                      <Route
                        path="/self-test/setup"
                        element={<LazySelfTestSetupPage />}
                      />
                      <Route
                        path="/self-test/exercise"
                        element={<LazySelfTestExercisePage />}
                      />
                      <Route
                        path="/self-test/report"
                        element={<LazySelfTestReportPage />}
                      />

                      {/* Legacy */}
                      {LEGACY_ROUTES}
                    </Routes>
                  </LocalizationProvider>
                </JoyrideProvider>
              </ThemeProvider>
            </ThemeSelectorContext.Provider>
          </AuthBackendProvider>
        </AuthProvider>
        <PreloadFiles />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

const PageviewLogging: React.FC<React.PropsWithChildren> = ({ children }) => {
  usePageviewLogging();

  return <>{children}</>;
};

export function LazyPage<T extends { default: React.ComponentType }>(
  factory: () => Promise<T>,
  Suspense = SuspenseLoadingPage,
) {
  const LazyComponent = React.lazy(factory);

  return () => (
    <PageviewLogging>
      <Suspense>
        <LazyComponent />
      </Suspense>
    </PageviewLogging>
  );
}

const LazyUIDemoPage = LazyPage(() => import('./features/chrome/UiDemoPage'));

const LazyPatientActivityOutlet = LazyPage(
  () => import('./features/patients/outlets/PatientActivityOutlet'),
);
const LazyPatientSessionsOutlet = LazyPage(
  () => import('./features/patients/outlets/PatientSessionsOutlet'),
);
const LazyPatientProgressOutlet = LazyPage(
  () => import('./features/patients/outlets/PatientProgressOutlet'),
);
const LazyPatientPlansOutlet = LazyPage(
  () => import('./features/patients/outlets/PatientPlansOutlet'),
);
const LazyPatientFormsOutlet = LazyPage(
  () => import('./features/patients/outlets/PatientFormsOutlet'),
);
const LazyPatientCommunicationOutlet = LazyPage(
  () => import('./features/patients/outlets/PatientCommunicationOutlet'),
);

const LazyPatientPlanReplayOutlet = LazyPage(
  () => import('./features/patients/outlets/PatientPlanReplayOutlet'),
);

const LazyPrescriptionExerciseListOutlet = LazyPage(
  () => import('./features/prescription/PrescriptionExerciseListOutlet'),
);
const LazyPlanReportList = LazyPage(
  () => import('./features/prescription/PlanReportListOutlet'),
);
const LazySelfTestReportList = LazyPage(
  () => import('./features/self-test/SelfTestReportList'),
);
const LazySelfTestExerciseListOutlet = LazyPage(
  () => import('./features/self-test/SelfTestExerciseListOutlet'),
);
const LazyTriagePage = LazyPage(() => import('./features/triage/TriagePage'));
const LazyTriageFormPage = LazyPage(
  () => import('./features/triage/TriageFormPage'),
);
const LazyTriageFormEndPage = LazyPage(
  () => import('./features/triage/TriageFormEndPage'),
);
const LazyTriageRecommendationPage = LazyPage(
  () => import('./features/triage/TriageRecommendationPage'),
);

const LazyCalendlyPage = LazyPage(
  () => import('./features/triage/CalendlyPage'),
);

const LazyCommunicationPage = LazyPage(
  () => import('./features/communication/CommunicationPage'),
);
const LazyMonitoringPage = LazyPage(() => import('./features/rtm/RtmPage'));
const LazyRtmCPTCodes = LazyPage(() => import('./features/rtm/RtmCPTCodes'));
const LazyRtmMonitoredPatients = LazyPage(
  () => import('./features/rtm/RtmMonitoredPatients'),
);

const LazyAppointmentTokenPage = LazyPage(
  () => import('./features/appointment/AppointmentTokenPage'),
);

const LazyAppointmentLobbyPage = LazyPage(
  () => import('./features/appointment/telehealth/TelehealthLobby'),
);

const LazyPrescriptionSessionPage = LazyPage(
  () => import('./features/prescription/session/PrescriptionSessionPage'),
);

const LazyAdminAppointmentPage = LazyPage(
  () => import('./features/admin/appointment/AdminAppointment'),
);

const LazyCalendlySyncPage = LazyPage(
  () => import('./features/calendly/CalendlySync'),
);

const LazyCreateModelPage = LazyPage(
  () => import('./features/model-manager/CreateModelForm'),
);

const LazyPatientsViewPage = LazyPage(
  () => import('./features/patients/PatientViewPage'),
);

const LazyAuthPage = LazyPage(() => import('./features/auth/AuthForm'));

const LazySignupPage = LazyPage(() => import('./features/auth/SignupForm'));

const LazySchedulePage = LazyPage(() => import('./features/calendly/Embed'));

const LazyFormPage = LazyPage(() => import('./features/form/FormPage'));

const LazyFormWebviewPage = LazyPage(
  () => import('./features/form/FormWebviewPage'),
  SuspenseStandalonePage,
);

const LazyConsentTokenPage = LazyPage(
  () => import('./features/form/ConsentTokenPage'),
);

const LazySelfTestPage = LazyPage(
  () => import('./features/self-test/SelfTestPage'),
);

const LazySelfTestIntroPage = LazyPage(
  () => import('./features/self-test/intro/SelfTestIntroPage'),
);

const LazySelfTestSetupPage = LazyPage(
  () => import('./features/self-test/intro/SelfTestSetupPage'),
);

const LazySelfTestSetupVideoPage = LazyPage(
  () => import('./features/self-test/intro/SelfTestSetupVideoPage'),
);

const LazySelfTestExercisePage = LazyPage(
  () => import('./features/self-test/exercise/SelfTestExercisePage'),
);

const LazySelfTestReportPage = LazyPage(
  () => import('./features/self-test/report/SelfTestReportPage'),
);

const LazyHEPDashboardPage = LazyPage(
  () => import('./features/prescription/hep-dashboard/HEPDashboardPage'),
);

const LazyHEPDashboardWebviewPage = LazyPage(
  () => import('./features/prescription/hep-dashboard/HEPDashboardWebviewPage'),
);

const LazyHEPIntroPage = LazyPage(
  () => import('./features/prescription/intro/PrescriptionIntroPage'),
);

const LazyHEPSetupVideoPage = LazyPage(
  () => import('./features/prescription/intro/PrescriptionSetupVideoPage'),
);

const LazyHEPSetupPage = LazyPage(
  () => import('./features/prescription/intro/PrescriptionSetupPage'),
);

const LazySessionHistoryPage = LazyPage(
  () => import('./features/appointment/history/SessionHistory'),
);

const LazySessionHistoryRomOutlet = LazyPage(
  () => import('./features/appointment/history/rom/RomOutlet'),
);

const LazyRomReportWebviewPage = LazyPage(
  () => import('./features/appointment/history/rom/RomReportWebviewPage'),
);

const LazySessionHistoryScreenshotsOutlet = LazyPage(
  () => import('./features/appointment/history/ScreenshotsOutlet'),
);

const LazySessionHistoryExerciseReplayOutlet = LazyPage(
  () => import('./features/appointment/history/SessionExerciseReplayOutlet'),
);

const LazySessionViewPage = LazyPage(
  () => import('./features/appointment/SessionViewPage'),
);

const LazyPatientSessionViewPage = LazyPage(
  () => import('./features/appointment/PatientSessionViewPage'),
);

const LazyPlanViewPage = LazyPage(
  () => import('./features/prescription/PlanViewPage'),
);

const LazyPatientPlanViewPage = LazyPage(
  () => import('./features/prescription/PatientPlanViewPage'),
);

const LazySettingsPage = LazyPage(
  () => import('./features/account/SettingsPage'),
);

const LazyHelpPage = LazyPage(() => import('./features/account/HelpPage'));

const LazyAppointmentSummaryWebviewPage = LazyPage(
  () => import('./features/appointment/summary/AppointmentSummaryWebviewPage'),
);

const DefferedSubmissions: React.FC = () => {
  useDeferredStatsSubmission();
  return null;
};
