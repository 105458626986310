import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { useAuthContext } from '../../api/firebase';
import { FullscreenContainer } from './StandalonePage';
import { RequireVifiveAuth } from '../../lib/signup';
import { useTheme, Theme } from '@mui/material/styles';
import { SidebarActions, SidebarNavigation } from './Navigation';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { BoxProps } from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';

export const drawerWidth = 240;
export const innerDrawerWidth = 360;

export interface PageProps {
  title: string;
}

const Main: React.FC<BoxProps> = ({ ...props }) => (
  <Box
    component="main"
    {...props}
    sx={{
      flexGrow: 1,
      width: { xs: '100%', sm: `calc(100% - ${drawerWidth}px)` },
      ...props.sx,
    }}
  />
);

export const Page: React.FC<PropsWithChildren<PageProps>> = ({
  title,
  children,
}) => {
  const { authState } = useAuthContext();
  const showDrawer = !!authState;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {showDrawer ? <ResponsiveDrawer /> : null}
      <Main>
        <Toolbar sx={{ display: { sm: 'none' } }} />
        {children}
      </Main>
    </Box>
  );
};

// https://mui.com/material-ui/react-drawer/#responsive-drawer
export const ResponsiveDrawer: React.FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Stack height="100%">
      <Toolbar>
        <HeaderVifiveLogo />
      </Toolbar>
      <SidebarNavigation isCollapsed={false} />
      <StickyActionsContainer>
        <SidebarActions />
      </StickyActionsContainer>
    </Stack>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: { sm: 'none', minHeight: '56px', maxHeight: '56px' },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <HeaderVifiveLogo />
        </Toolbar>
        <Divider />
      </AppBar>
      <Box sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            flexShrink: 0,
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              // Override default background on temporary drawer
              backgroundImage: 'none',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            flexShrink: 0,
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

const StickyActionsContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  bottom: 0,
}));

export const AuthPage: React.FC<PropsWithChildren<PageProps>> = (pageProps) => (
  <RequireVifiveAuth>
    <Page {...pageProps} />
  </RequireVifiveAuth>
);

export const VideoSessionContainer = styled('main')(({ theme }) => ({
  position: 'fixed',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
}));

export const VideoSessionPage: React.FC<PropsWithChildren<PageProps>> = ({
  children,
}) => (
  <VideoSessionContainer>
    <CssBaseline />
    {children}
  </VideoSessionContainer>
);

// True height of page main area minus the app bar
export const PAGE_MAIN_HEIGHT = (theme: Theme) => `calc(100vh - 64px - ${theme.spacing(4)})`

const FullscreenMain = styled('main')(({ theme }) => ({
  padding: theme.spacing(3),
  overflow: 'auto',
  flexGrow: 1,
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const FullscreenPage: React.FC<PropsWithChildren<PageProps>> = ({
  children,
}) => (
  <FullscreenContainer>
    <CssBaseline />
    <AppBar position="fixed">
      <Toolbar>
        <HeaderVifiveLogo />
      </Toolbar>
    </AppBar>
    <FullscreenMain>
      <DrawerHeader />
      {children}
    </FullscreenMain>
  </FullscreenContainer>
);

export const BlankPage: React.FC<PropsWithChildren> = ({ children }) => (
  <FullscreenContainer>
    <CssBaseline />
    <FullscreenMain>{children}</FullscreenMain>
  </FullscreenContainer>
);

export const SuspenseLoadingPage: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <React.Suspense fallback={<Page title="ViFiVE" />}>{children}</React.Suspense>
);

type ContentHeaderVariant = 'positive' | 'negative';

const ContentHeaderBase: React.FC<
  PropsWithChildren<BoxProps & { variant?: ContentHeaderVariant }>
> = ({ ...props }) => <Box {...props} />;

export const ContentHeader = styled(ContentHeaderBase)(
  ({ theme, variant }) => ({
    backgroundColor:
      variant === 'positive'
        ? theme.palette.background.secondary
        : variant === 'negative'
        ? theme.palette.background.primary
        : theme.palette.background.secondary,
    zIndex: 999,
  }),
);

export const ContentMain = styled(Box, {
  shouldForwardProp: (props) => props !== 'disablePadding',
})<{ disablePadding?: boolean }>(({ theme, disablePadding }) => ({
  padding: disablePadding ? 'none' : `${theme.spacing(4)} ${theme.spacing(5)}`,
  flexGrow: 1,
  overflowY: 'auto',
}));

export const InnerPageDrawerMain: React.FC<PropsWithChildren<BoxProps>> = ({
  children,
  ...props
}) => (
  <Box {...props} sx={{ ...props.sx, ml: { sm: `${innerDrawerWidth}px` } }}>
    {children}
  </Box>
);

export const InnerPageDrawer: React.FC<BoxProps> = ({ ...props }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      position="fixed"
      left={0}
      top={{
        xs: theme.mixins.toolbar.minHeight,
        sm: 0,
      }}
      ml={{ xs: 0, sm: `${drawerWidth}px` }}
      width={{
        xs: '100%',
        sm: `calc(100% - ${drawerWidth}px)`,
        md: innerDrawerWidth,
      }}
      height="100%"
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: theme.palette.background.primary,
        borderRight: `1px solid ${theme.palette.background.quaternerary}`,
      }}
      overflow="auto"
    />
  );
};

export const HeaderVifiveLogo: React.FC<BoxProps> = ({ ...props }) => {
  const theme = useTheme();
  return (
    <Box
      component={Link}
      display="flex"
      alignItems="center"
      justifyContent="center"
      to="/sessions"
      height={`${theme.mixins.toolbar.minHeight}px`}
      p={1}
      {...props}
    >
      <img
        src="/vifive.svg"
        alt="vifive logo"
        style={{
          // Height of toolbar minus padding of container
          height: `calc(${theme.mixins.toolbar.minHeight}px - ${theme.spacing(
            1,
          )} - ${theme.spacing(1)})`,
        }}
      />
    </Box>
  );
};

export function useContentShouldBeMobile() {
  // Drawer collapses at < 'sm'.
  // Content should reflow at < 'md' to prevent excessive
  // squishing while the drawer is still present.
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
}
