import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import { useWhoAmI } from '../../api/clinician';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import PeopleIcon from '@mui/icons-material/People';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TextButton, TextNavButton } from './Button';
import { useTheme } from '@mui/material/styles';
import { useAuthContext } from '../../api/firebase';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import InsightsIcon from '@mui/icons-material/Insights';
import { buildSettingsProfileRoute } from '../../routes';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useInterlocutors } from '../../api/message';

const SidebarCollapsedContext = React.createContext(false);

export const SidebarNavigation: React.FC<{ isCollapsed: boolean }> = ({
  isCollapsed,
}) => {
  const { data: whoami, isLoading } = useWhoAmI();
  const interlocutorsQ = useInterlocutors();
  const isStaff = whoami?.clinician?.isStaff;
  const isClinician = !!whoami?.clinician;
  const isPatient = !!whoami?.patient;

  return (
    <SidebarCollapsedContext.Provider value={isCollapsed}>
      <Stack height="100%" overflow="auto">
        <List>
          {isClinician ? (
            <>
              <NavItem
                icon={<StackedBarChartIcon />}
                text="Monitoring"
                to="/monitoring"
              />
              <NavItem icon={<PeopleIcon />} text="Patients" to="/patients" />
            </>
          ) : null}
          <NavItem
            icon={<CalendarViewDayIcon />}
            text="Sessions"
            to="/sessions"
          />
          {isLoading || isPatient ? (
            <>
              <NavItem
                icon={<AccessibilityNewIcon />}
                text="Exercise Plans"
                to="/plans"
              />
              <NavItem
                icon={<InsightsIcon />}
                text="Exercise Progress"
                to={`/progress/${whoami?.patient?.id!}`}
              />
              {/* <NavItem
                icon={<BiotechIcon />}
                text="Self Test"
                to="/self-test"
              /> */}
              {!!interlocutorsQ.data?.length && (
                <NavItem
                  icon={<QuestionAnswerIcon />}
                  text="Communication"
                  to="/communication"
                />
              )}
            </>
          ) : null}
        </List>
        {isStaff ? (
          <>
            <List subheader={<ListSubheader>Staff</ListSubheader>}>
              <NavItem
                icon={<EventRepeatIcon />}
                text="Calendly Sync"
                to="/admin/calendly"
              />
              <NavItem
                icon={<CalendarMonthIcon />}
                text="Appointments"
                to="/admin/appointment"
              />
            </List>
            <Divider />
          </>
        ) : null}
      </Stack>
    </SidebarCollapsedContext.Provider>
  );
};

const NavItem: React.FC<{
  text: string;
  to: string;
  icon?: React.ReactNode;
}> = ({ text, to, icon }) => {
  const collapsed = useContext(SidebarCollapsedContext);
  const theme = useTheme();
  return (
    <ListItem
      disablePadding
      selected={location.pathname.startsWith(to)}
      sx={{
        '&.Mui-selected': {
          backgroundColor: theme.palette.background.quaternerary,
        },
      }}
    >
      <ListItemButton component={Link} to={to}>
        {icon !== undefined ? <ListItemIcon>{icon}</ListItemIcon> : null}
        {collapsed ? null : (
          <ListItemText>
            <Typography variant="h5">{text}</Typography>
          </ListItemText>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export const SidebarActions: React.FC = () => {
  const { auth } = useAuthContext();
  const feedbackFormUrl = 'https://forms.gle/KXrHtwGSkYqDANKK9';
  const theme = useTheme();
  return (
    <Stack alignItems="baseline" p={3}>
      <TextNavButton to={buildSettingsProfileRoute()}>Settings</TextNavButton>
      <TextButton onClick={() => window.open(feedbackFormUrl, '_blank')}>
        Feedback
      </TextButton>
      <TextButton
        sx={{ color: theme.palette.primary.main }}
        onClick={() => auth.signOut()}
      >
        Log Out
      </TextButton>
    </Stack>
  );
};
