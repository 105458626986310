import { useQuery } from 'react-query';
import { naiveFetchJSON } from './http';

function getLottieAnimation(path: string) {
  return naiveFetchJSON<any>(`/lottie/${path}.json`);
}

export function useLottieAnimation(path: string | null) {
  return useQuery(['lottie', path], {
    queryFn: () => (path ? getLottieAnimation(path) : null),
    enabled: !!path,
  });
}

export async function getLottieAnimationLength(path: string | null) {
  if (!path) {
    return null;
  }
  const { ip, op, fr } = await getLottieAnimation(path);
  return (op - ip) / fr;
}
