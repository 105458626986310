import Chip, { ChipProps } from '@mui/material/Chip';
import React, { useEffect, useRef, useState } from 'react';
import { TimestampDto } from '../api/backend-client/data-contracts';
import { Detail } from '../features/chrome/Typography';

export const DEFAULT_APPOINTMENT_DURATION = 45;

export function parseDate(dateStr: string): Date {
  return new Date(dateStr);
}

export function formatFullTime(date: Date) {
  return date.toLocaleString();
  // en-us: "9/7/2022, 10:55:39 AM"
}

export function stringToFormattedDateString(date: string): string {
  // "11/7/2023"
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

export function stringToFormattedDateHourMinute(date: string): string {
  // "11/7/2023"
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function createTimestampDto(date: Date): TimestampDto {
  const seconds = date.getTime() / 1000;

  const _seconds = Math.floor(seconds);
  const _nanoseconds = (seconds % 1) * 1000000000;

  return {
    _seconds,
    _nanoseconds,
  };
}

export function timestampToSeconds(dto: TimestampDto) {
  return dto._seconds + dto._nanoseconds / 1e9;
}

export function timestampToDate(timestampDto: TimestampDto): Date {
  return new Date(timestampToSeconds(timestampDto) * 1000);
}

// https://blog.webdevsimplified.com/2020-07/relative-time-format/
const formatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: 'auto',
});

const RELATIVE_DIVISIONS: Array<{
  amount: number;
  name: Intl.RelativeTimeFormatUnit;
}> = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
];

export function formatTimeAgo(date: Date) {
  let duration = (date.valueOf() - Date.now()) / 1000;

  for (const { amount, name } of RELATIVE_DIVISIONS) {
    if (Math.abs(duration) < amount) {
      return formatter.format(Math.round(duration), name);
    }
    duration /= amount;
  }
}

function formatWithinAMonth(date: Date) {
  return `${date.toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
  })} (${formatTimeAgo(date)})`;
  // en-us: "Sep 7 (in 12 days)"
}

function formatWithinAYear(date: Date) {
  return date.toLocaleDateString(undefined, { dateStyle: 'medium' });
  // en-us: "Sep 7, 2022"
}

const DAY_IN_MS = 1000 * 60 * 60 * 24;

const FORMAT_DIVISIONS: Array<{
  days: number;
  formatter: (d: Date) => string;
}> = [
  { days: 30, formatter: formatWithinAMonth },
  { days: Number.POSITIVE_INFINITY, formatter: formatWithinAYear },
];

export function formatHumanTime(date: Date) {
  const delta = Math.abs(Date.now() - date.valueOf()) / DAY_IN_MS;
  for (const { days, formatter } of FORMAT_DIVISIONS) {
    if (delta < days) {
      return formatter(date);
    }
  }
}

export function formatTimeOfDay(date: Date) {
  return date.toLocaleTimeString(undefined, { timeStyle: 'short' });
  // en-us: "11:05 AM"
}

export function splitHoursAndMinutes(minutes: number) {
  return [Math.floor(minutes / 60), minutes % 60];
}

export function calculateDiffInDays(date1: Date, date2: Date) {
  return Math.floor(
    (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24),
  );
}

// H hr(s) M min(s) S (s)
export function formatDuration(duration: number): string {
  const [hours, minutes] = splitHoursAndMinutes(duration);
  if (hours == 0 && minutes == 0) return '1 min';
  return `${hours > 0 ? hours + ' hr' : ''}${hours > 1 ? 's' : ''}${
    hours > 0 && minutes > 0 ? ' ' : ''
  }${minutes > 0 ? minutes + ' mins' : ''}`;
}

// HH:MM:SS
export function formatClockDuration(totalSeconds: number): string {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = totalSeconds - hours * 3600 - minutes * 60;

  function prefix(value: number) {
    return value < 10 ? '0' : '';
  }

  return `${prefix(hours) + hours}:${prefix(minutes) + minutes}:${
    prefix(seconds) + seconds
  }`;
}

export const TimeChip: React.FC<{ date: Date } & ChipProps> = ({
  date,
  ...props
}) => (
  <Chip
    label={<Detail>{formatHumanTime(date)}</Detail>}
    sx={{ padding: '4px 8px', textTransform: 'uppercase' }}
    {...props}
  />
);

export function isValidDate(candidate: string): boolean {
  const date = new Date(candidate);
  return date.getTime() === date.getTime();
}

export function datesAreOnSameDay(first: Date, second: Date): boolean {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

export function subtractTimestamps(
  before: TimestampDto,
  after: TimestampDto,
): TimestampDto {
  return {
    _seconds: after._seconds - before._seconds,
    _nanoseconds: after._nanoseconds - before._nanoseconds,
  };
}

export function formatTimestampAsMinutes(timestamp: TimestampDto) {
  const minutes = Math.floor(timestamp._seconds / 60);
  return formatDuration(minutes);
}

export function formatTimestampAsDate(timestamp: TimestampDto) {
  return new Date(
    (timestamp._seconds + timestamp._nanoseconds / 1000000000) * 1000,
  ).toLocaleDateString(undefined, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });
}

export function useSecondsCountdown(): [
  boolean,
  number,
  (seconds: number) => void,
] {
  const now = new Date().getTime();
  const [finish, setFinish] = useState(now);
  const [time, setTime] = useState(now);
  const requestId = useRef<number | undefined>();

  function startCountdown(seconds: number) {
    const currentTime = new Date().getTime();
    setTime(currentTime);
    setFinish(currentTime + seconds * 1000);
  }

  function updateCountdown() {
    const now = new Date().getTime();
    if (finish - now <= 0) {
      setTime(finish);
      return;
    }
    setTime(now);
    requestId.current = requestAnimationFrame(updateCountdown);
  }

  useEffect(() => {
    updateCountdown();

    return () => {
      if (requestId.current) {
        cancelAnimationFrame(requestId.current);
      }
    };
  }, [finish]);

  const remaining = Math.round((finish - time) / 1000);

  return [remaining > 0, remaining, startCountdown];
}

export function useSecondsCountdownIndefinite(): [
  boolean,
  number | undefined,
  (seconds: number) => void,
  () => void,
] {
  const [countdownIsIndefinite, setCountdownIsIndefinite] = useState(false);

  const [countdownIsActive, timeRemaining, setSecondsCountdown] =
    useSecondsCountdown();

  function startIndefiniteCountdown() {
    setCountdownIsIndefinite(true);
    setSecondsCountdown(0);
  }

  function startCountdown(seconds: number) {
    setCountdownIsIndefinite(false);
    setSecondsCountdown(seconds);
  }

  return [
    countdownIsIndefinite ? true : countdownIsActive,
    countdownIsIndefinite ? undefined : timeRemaining,
    startCountdown,
    startIndefiniteCountdown,
  ];
}
