import Button, { ButtonProps } from '@mui/material/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import SecurityIcon from '@mui/icons-material/Security';

export type NavButtonProps = ButtonProps &
  LinkProps &
  React.RefAttributes<HTMLAnchorElement>;

export const NavButton: React.FC<NavButtonProps> = ({ ...props }) => (
  <Button component={Link} {...props} />
);

const primaryButtonStyle: (theme: Theme) => SxProps = (theme) => ({
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
});

export const BasePrimaryButton = styled(Button)(({ theme }) => ({
  ...primaryButtonStyle(theme),
}));

export const PrimaryButton: React.FC<ButtonProps> = ({ ...props }) => (
  <BasePrimaryButton variant="contained" {...props} />
);

export const BasePrimaryNavButton = styled(NavButton)(({ theme }) => ({
  ...primaryButtonStyle(theme),
}));

export const PrimaryNavButton: React.FC<NavButtonProps> = ({ ...props }) => (
  <BasePrimaryNavButton variant="contained" {...props} />
);

const secondaryButtonStyle: (theme: Theme) => SxProps = (theme) => ({
  backgroundColor: theme.palette.background.tertiary,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.background.tertiary,
  },
});

export const BaseSecondaryButton = styled(Button)(({ theme }) => ({
  ...secondaryButtonStyle(theme),
}));

export const SecondaryButton: React.FC<ButtonProps> = ({ ...props }) => (
  <BaseSecondaryButton variant="contained" {...props} />
);

export const BaseSecondaryNavButton = styled(NavButton)(({ theme }) => ({
  ...secondaryButtonStyle(theme),
}));

export const SecondaryNavButton: React.FC<NavButtonProps> = ({ ...props }) => (
  <BaseSecondaryNavButton variant="contained" {...props} />
);

const textButtonStyle: (theme: Theme) => SxProps = (theme) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
});

export const BaseTextButton = styled(Button)(({ theme }) => ({
  ...textButtonStyle(theme),
}));

export const TextButton: React.FC<ButtonProps> = ({ ...props }) => (
  <BaseTextButton variant="text" {...props} />
);

export const BaseTextNavButton = styled(NavButton)(({ theme }) => ({
  ...textButtonStyle(theme),
}));

export const TextNavButton: React.FC<NavButtonProps> = ({ ...props }) => (
  <BaseTextNavButton variant="text" {...props} />
);

export const AdminButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <Button
    variant="contained"
    color="secondary"
    {...props}
    sx={{ width: 'fit-content', ...props.sx }}
  >
    <SecurityIcon />
    {children}
  </Button>
);
