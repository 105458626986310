import { CSSProperties } from 'react';

export const absoluteCenterStyle: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const flexColumnStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

export const flexRowStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
};

export const flexCenterStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const roundedStyle: CSSProperties = {
  borderRadius: `4px`,
};

export const shadowStyle: CSSProperties = {
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 10px 15px',
};

export const growHeightStyle: CSSProperties = {
  height: '100%',
};

export const growWidthStyle: CSSProperties = {
  width: '100%',
};

export const growSizeStyle: CSSProperties = {
  ...growHeightStyle,
  ...growWidthStyle,
};

export const disabledStyle: CSSProperties = { opacity: '50%' };

export const detailStyle: CSSProperties = { opacity: '70%' };