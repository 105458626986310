import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useBackendApi } from '../lib/backend';
import {
  BillableCodeUpdateReq,
  CreateMessageDto,
  EpisodeOpenDto,
  EpisodeUpdateDto,
} from './backend-client/data-contracts';
import { useIdToken } from './firebase';
import { unwrapResponse } from './util';

export function useSendMessage(isClinician: boolean) {
  return isClinician ? useSendMessageToPatient() : useSendMessageToClinician();
}

export function useMessages(isClinician: boolean, interlocutorId: string) {
  return isClinician
    ? useMessagesFromPatient(interlocutorId)
    : useMessagesFromClinician(interlocutorId);
}

export function useInterlocutors() {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['interlocutors'], {
    queryFn: () =>
      api.messageControllerListInterlocutors().then(unwrapResponse),
    enabled: !!idToken.data,
  });
}

function useSendMessageToPatient() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: CreateMessageDto) => {
      const response = await api.messageControllerSendMessageToPatient(body);
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clinicianMessages');
      },
    },
  );
}

function useSendMessageToClinician() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: CreateMessageDto) => {
      const response = await api.messageControllerSendMessageToClinician(body);
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('patientMessages');
      },
    },
  );
}

function useMessagesFromPatient(interlocutorId: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['clinicianMessages', interlocutorId], {
    queryFn: () =>
      api
        .messageControllerGetMessagesFromPatient({ interlocutorId })
        .then(unwrapResponse),
    refetchInterval: 10 * 1000,
    enabled: !!idToken.data,
  });
}

function useMessagesFromClinician(interlocutorId: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['patientMessages', interlocutorId], {
    queryFn: () =>
      api
        .messageControllerGetMessagesFromClinician({ interlocutorId })
        .then(unwrapResponse),
    refetchInterval: 10 * 1000,
    enabled: !!idToken.data,
  });
}
