import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useBackendApi } from '../lib/backend';
import { Api } from './backend-client/Api';
import { AppointmentDto, FormDto } from './backend-client/data-contracts';
import { useIdToken } from './firebase';
import { getJwtApi } from './jwtApi';
import { unwrapResponse } from './util';

export type FormQuery = Parameters<Api['formControllerListForms']>[0];

export function useForms(query?: FormQuery) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['forms', query], {
    queryFn: () => api.formControllerListForms(query).then(unwrapResponse),
    enabled: !!idToken.data && !!query,
  });
}

export function useForm(id?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['forms', id], {
    queryFn: () => api.formControllerGetForm(id!).then(unwrapResponse),
    enabled: !!idToken.data && !!id,
  });
}

export function useFormForAppointment(appointmentId?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['forms', appointmentId], {
    queryFn: () =>
      api
        .formControllerGetFormForAppointment(appointmentId!)
        .then(unwrapResponse),
    enabled: !!idToken.data && !!appointmentId,
  });
}

export function useGetFormWithToken(token: string) {
  const jwtApi = getJwtApi(token);

  return useQuery(['form', token], {
    queryFn: async () => {
      const res = await jwtApi.consentControllerGetConsentFormWithToken();
      return res.data;
    },
  });
}

export function useUpdateForm() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: FormDto) => {
      const response = await api.formControllerUpdateForm(body);
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('forms');
      },
    },
  );
}

export function useCreateAppointmentForm() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: {
      appointment: AppointmentDto;
      formType: FormDto['type'];
    }) => {
      const response = await api.formControllerCreateAppointmentForm(
        body.formType,
        body.appointment,
      );
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('forms');
      },
    },
  );
}
