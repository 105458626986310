import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useBackendApi } from '../lib/backend';
import { Api } from './backend-client/Api';
import {
  ExerciseStats,
  SaveSelfTestStatsDto,
} from './backend-client/data-contracts';
import { useIdToken } from './firebase';
import { unwrapResponse } from './util';
import { ExerciseStatsSource } from '@vifive/coach';

export type ExerciseStatQuery = Parameters<
  Api['exerciseStatControllerGetPatientExerciseStats']
>[0];

export function usePatientExerciseStats(query?: ExerciseStatQuery) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['exerciseStats', query], {
    queryFn: () =>
      api
        .exerciseStatControllerGetPatientExerciseStats(query!)
        .then(unwrapResponse),
    enabled: !!idToken.data && !!query,
  });
}

export function useExerciseStatsBySession(query?: {
  patientId: string;
  prescriptionId?: string;
  source?: ExerciseStatsSource;
}) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(
    ['exerciseStatsBySession', query?.prescriptionId, query?.source],
    {
      queryFn: () =>
        api
          .exerciseStatControllerGetExerciseStatsBySession(query!)
          .then(unwrapResponse),
      enabled: !!idToken.data && !!query,
    },
  );
}

type UpdateStatsQ = ReturnType<typeof useUpdateExerciseStat>;
export type StatsSubmission = Parameters<UpdateStatsQ['mutate']>[0];

export function useUpdateExerciseStat() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: { patientId: string; data: ExerciseStats }) => {
      const response = await api.exerciseStatControllerUpdateExerciseStat(
        body.patientId,
        body.data,
      );
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['exerciseStats']);
      },
    },
  );
}

export function useSaveSelfTestStats() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: SaveSelfTestStatsDto) => {
      const response = await api.exerciseStatControllerSaveSelfTestStats(body);
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['exerciseStatsBySession']);
      },
    },
  );
}
