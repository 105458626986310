// Routes in this file should correspond to Routes in App.tsx

export const appointmentIdParameter = ':appointmentId';
export const prescriptionIdParameter = ':prescriptionId';
export const patientIdParameter = ':patientId';
export const formIdParameter = ':formId';
export const statIdParameter = ':statId';
export const muteParameter = ':mute';

/**
 * /sessions/:appointmentId
 */
export const buildSessionsRoute = (
  appointmentId: string = appointmentIdParameter,
) => `/sessions/${appointmentId}`;

/**
 * /patients/:patientId/sessions/:appointmentId
 */
export const buildPatientSessionsRoute = (
  patientId: string = patientIdParameter,
  appointmentId: string = appointmentIdParameter,
) => `/patients/${patientId}/sessions/${appointmentId}`;

/**
 * /sessions/:appointmentId/history
 */
export const buildSessionsHistoryRoute = (
  appointmentId: string = appointmentIdParameter,
) => `/sessions/${appointmentId}/history`;

/**
 * /sessions/:appointmentId/history/rom
 */
export const buildSessionHistoryRomRoute = (
  appointmentId: string = appointmentIdParameter,
) => `/sessions/${appointmentId}/history/rom`;

/**
 * /sessions/:appointmentId/history/rom-embed
 */
export const buildSessionHistoryRomEmbedRoute = (
  appointmentId: string = appointmentIdParameter,
) => `/sessions/${appointmentId}/history/rom-embed`;

/**
 * /sessions/:appointmentId/summary-embed
 */
export const buildSessionSummaryEmbedRoute = (
  appointmentId: string = appointmentIdParameter,
) => `/sessions/${appointmentId}/summary-embed`;

/**
 * /sessions/:appointmentId/history/screenshots
 */
export const buildSessionHistoryScreenshotsRoute = (
  appointmentId: string = appointmentIdParameter,
) => `/sessions/${appointmentId}/history/screenshots`;

/**
 * /sessions/:appointmentId/history/replay
 */
export const buildSessionHistoryReplayRoute = (
  appointmentId: string = appointmentIdParameter,
) => `/sessions/${appointmentId}/history/replay`;

/**
 * /telelealth/:appointmentId/join
 */
export const buildTelehealthJoinRoute = (
  appointmentId: string = appointmentIdParameter,
) => `/telehealth/${appointmentId}/join`;

/**
 * /telelealth/join
 */
export const buildTelehealthTokenJoinRoute = () => `/telehealth/join`;

/**
 * /plans/:prescriptionId
 */
export const buildPlansRoute = (
  prescriptionId: string = prescriptionIdParameter,
) => `/plans/${prescriptionId}`;

/**
 * /plans/:prescriptionId/exercise-list
 */
export const buildPlansExerciseListRoute = (
  prescriptionId: string = prescriptionIdParameter,
) => `/plans/${prescriptionId}/exercise-list`;

/**
 * /plans/:prescriptionId/reports
 */
export const buildPlansReportsRoute = (
  prescriptionId: string = prescriptionIdParameter,
) => `/plans/${prescriptionId}/reports`;

export const buildPatientPlansRoute = (
  patientId: string = patientIdParameter,
  prescriptionId: string = prescriptionIdParameter,
) => `/patients/${patientId}/plans/${prescriptionId}`;

/**
 * /plans/:prescriptionId/exercise
 */
export const buildPlansExerciseRoute = (
  prescriptionId: string = prescriptionIdParameter,
  mute: string = muteParameter,
) => `/plans/${prescriptionId}/exercise?mute=${mute}`;

/**
 * /plans/:prescriptionId/intro
 */
export const buildPlansIntroRoute = (
  prescriptionId: string = prescriptionIdParameter,
) => `/plans/${prescriptionId}/intro`;

/**
 * /plans/:prescriptionId/video
 */
export const buildPlansVideoRoute = (
  prescriptionId: string = prescriptionIdParameter,
) => `/plans/${prescriptionId}/video`;

/**
 * /plans/:prescriptionId/setup
 */
export const buildPlansSetupRoute = (
  prescriptionId: string = prescriptionIdParameter,
) => `/plans/${prescriptionId}/setup`;

/**
 * /progress/:patientId
 */
export const buildProgressRoute = (patientId: string = patientIdParameter) =>
  `/progress/${patientId}`;

/**
 * /progress/:patientId/webview
 */
export const buildProgressWebviewRoute = (
  patientId: string = patientIdParameter,
) => `/progress/${patientId}/webview`;

/**
 * /progress/plans/:prescriptionId/replay/:statId"
 */
export const buildPlanReplayRoute = (
  prescriptionId: string = prescriptionIdParameter,
  statId: string = statIdParameter,
) => `/progress/plans/${prescriptionId}/replay/${statId}`;

/**
 * /patients/:patientId
 */
export const buildPatientsRoute = (patientId: string = patientIdParameter) =>
  `/patients/${patientId}`;

/**
 * /patients/:patientId/communication
 */
export const buildPatientsCommunicationRoute = (
  patientId: string = patientIdParameter,
) => `/patients/${patientId}/communication`;

/**
 * /settings/profile
 */
export const buildSettingsProfileRoute = () => '/settings/profile';

/**
 * /help
 */
export const buildHelpRoute = () => '/help';

/**
 * /form/:formId
 */
export const buildFormRoute = (formId: string = formIdParameter) =>
  `/form/${formId}`;

/**
 * /form/webview/:formId
 */
export const buildFormWebviewRoute = (formId: string = formIdParameter) =>
  `/form/webview/${formId}`;

/**
 * /form
 */
export const buildFormTokenRoute = () => '/form';

/**
 * /auth
 */
export const buildAuthRoute = () => '/auth';

/**
 * /signup
 */
export const buildSignupRoute = () => '/signup';

/**
 * /schedule
 */
export const buildScheduleRoute = () => '/schedule';

/**
 * /model-admin
 */
export const buildAdminModelRoute = () => '/model-admin';

/**
 * /admin/appointment
 */
export const buildAdminAppointmentRoute = () => '/admin/appointment';

/**
 * /admin/calendly
 */
export const buildAdminCalendlyRoute = () => '/admin/calendly';

/**
 * /self-test
 */
export const buildSelfTestRoute = () => '/self-test';

/**
 * /self-test/exercises
 */
export const buildSelfTestExercisesRoute = () => '/self-test/exercises';

/**
 * /self-test/reports
 */
export const buildSelfTestReportsRoute = () => '/self-test/reports';

/**
 * /self-test/intro
 */
export const buildSelfTestIntroRoute = () => '/self-test/intro';

/**
 * /self-test/video
 */
export const buildSelfTestVideoRoute = () => '/self-test/video';

/**
 * /self-test/setup
 */
export const buildSelfTestSetupRoute = () => '/self-test/setup';

/**
 * /self-test/exercise
 */
export const buildSelfTestExerciseRoute = () => '/self-test/exercise';

/**
 * /self-test/report
 */
export const buildSelfTestReportRoute = () => '/self-test/report';

/**
 * /monitoring/codes
 */
export const buildMonitoringCodesRoute = () => '/monitoring/codes';

/**
 * /monitoring/patients
 */
export const buildMonitoringPatientsRoute = () => '/monitoring/patients';
