import { Api } from './backend-client/Api';

type JwtApiEndPoints =
  | 'appointmentControllerCheckConsentAndCreateConnectionWithToken'
  | 'consentControllerApproveConsent'
  | 'consentControllerGetConsentFormWithToken';

export function getJwtApi(token: string) {
  return new Api({
    baseApiParams: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  }) as Pick<Api, JwtApiEndPoints>;
}
