import {
  Location,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
type FromLocation = Location & {
  state: {
    from: Location;
  };
};

export function isFromLocation(location: Location): location is FromLocation {
  return (
    typeof location.state === 'object' &&
    location.state !== null &&
    'from' in location.state
  );
}

export const useRoutePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.entries(params).reduce((path, [param, value]) => {
    return path?.replace('' + value, `:${param}`);
  }, location.pathname);
};

export function useCurrentParamValues(redirectTo: string) {
  const params = useParams();

  return Object.entries(params).reduce((path, [param, value]) => {
    return path.replace(`:${param}`, '' + value);
  }, redirectTo);
}

// https://mui.com/material-ui/guides/routing/#tabs
export function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export function useRedirectToFromLocation() {
  const navigate = useNavigate();
  const location = useLocation();

  return () =>
    navigate(
      `${location.state?.from?.pathname ?? '/'}${
        location.state?.from?.search ?? ''
      }`,
      { state: { redirect: true }, replace: true },
    );
}
