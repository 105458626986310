import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  ContentMain,
  BlankPage,
  useContentShouldBeMobile,
} from '../chrome/Page';
import { PrimaryNavButton } from '../chrome/Button';
import { TriageType, triageFormIdMap } from './constants';

const TriagePage: React.FC = () => {
  const isMobile = useContentShouldBeMobile();
  const height = isMobile ? '100%' : '90vh';
  const width = isMobile ? '100%' : '50%';

  useEffect(() => {
    const startTime = Date.now();
    localStorage.setItem('triageStartTime', startTime.toString());
  }, []);

  return (
    <BlankPage>
      <ContentMain
        disablePadding
        height={height}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={6}
      >
        <Stack width={width} alignItems="center" gap={6}>
          <Typography variant="h1" align="center">
            Welcome to Digital Triage Assessment
          </Typography>
          <Typography variant="body1" align="center">
            This assessment will help you determine the severity of your
            symptoms and provide guidance on what to do next.
          </Typography>
          <Typography variant="body1" align="center">
            Please select the option below to begin.
          </Typography>
          {Object.values(TriageType).map((option, i) => (
            <PrimaryNavButton
              key={i}
              to={`/triage/form?id=${triageFormIdMap[option as TriageType]}`}
              sx={{ width, fontWeight: 'bold' }}
            >
              {option}
            </PrimaryNavButton>
          ))}
        </Stack>
      </ContentMain>
    </BlankPage>
  );
};

export default TriagePage;
