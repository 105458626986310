import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  ContentMain,
  BlankPage,
  useContentShouldBeMobile,
} from '../chrome/Page';
import { PrimaryNavButton } from '../chrome/Button';
import { useSearchParams } from 'react-router-dom';
import { getTriageNumMovements } from './utils';

const TriageFormEndPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const numMovements = getTriageNumMovements(searchParams.get('type'));
  const isMobile = useContentShouldBeMobile();
  const height = isMobile ? '100%' : '90vh';
  const width = isMobile ? '100%' : '50%';

  return (
    <BlankPage>
      <ContentMain
        disablePadding
        height={height}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={6}
      >
        <Stack width={width} alignItems="center" gap={6}>
          <Typography variant="h1" align="center">
            It's time to perform a movement assessment.
          </Typography>
          <Typography variant="body1" align="center">
            {`The movement assessment will consist of two parts. First you will do a range of motion screening with ${
              numMovements - 1
            } movements. Next you will complete a Sit to Stand test, which requires a chair without wheels. This assessment should you take you 5 minutes to complete.`}
          </Typography>
          <Typography variant="body1" align="center">
            Please click the button below to begin the assessment.
          </Typography>
          <PrimaryNavButton
            to={`/self-test/intro?${searchParams}`}
            sx={{ width }}
          >
            Begin
          </PrimaryNavButton>
        </Stack>
      </ContentMain>
    </BlankPage>
  );
};

export default TriageFormEndPage;
