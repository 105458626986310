import { useQuery } from 'react-query';
import { useBackendApi } from '../lib/backend';
import { Api } from './backend-client/Api';
import { useIdToken } from './firebase';
import { unwrapResponse } from './util';

export function useAppointmentReport(appointmentId?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['reports', appointmentId], {
    queryFn: () =>
      api
        .appointmentReportControllerGetAppointmentReport(appointmentId!)
        .then(unwrapResponse),
    enabled: !!idToken.data && !!appointmentId,
  });
}

export function useAppointmentReportCsv(appointmentId?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['report-csv', appointmentId], {
    queryFn: () =>
      api
        .appointmentReportControllerGetAppointmentReportCsv(appointmentId!)
        .then(unwrapResponse),
    enabled: !!idToken.data && !!appointmentId,
  });
}

export type HEPReportQuery = Parameters<
  Api['reportControllerGetHomeExerciseReport']
>[0];

export function useHEPReport(query: HEPReportQuery | undefined) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['reports', query], {
    queryFn: () =>
      api.reportControllerGetHomeExerciseReport(query!).then(unwrapResponse),
    enabled: !!idToken.data && !!query,
  });
}

export function useROMReport(
  appointmentId: string | undefined,
  movementIndex: number,
) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery({
    queryKey: ['reports-rom', appointmentId, movementIndex],
    queryFn: () =>
      api
        .appointmentRomReportControllerGetAppointmentReport(
          appointmentId!,
          movementIndex.toString(),
        )
        .then(unwrapResponse),
    enabled: !!idToken.data && !!appointmentId,
  });
}

export type ActivityReportQuery = Parameters<
  Api['reportControllerGetActivityReport']
>[0];

export function useActivityReport(query: ActivityReportQuery | undefined) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery({
    queryKey: ['reports-activity', query],
    queryFn: () =>
      api.reportControllerGetActivityReport(query!).then(unwrapResponse),
    enabled: !!idToken.data && !!query,
  });
}
