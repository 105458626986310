import React from 'react';

import Typography, { TypographyProps } from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';


// Semantically useful typography:
// Have a few well defined types of text, such as "subject" "subject details" "copy" "info" "list item"
// Associate a small set of fonts with it

export const Title: React.FC<TypographyProps> = (props) => (
  <Typography variant="h4" fontWeight="bold" {...props} />
);

export const Subject: React.FC<TypographyProps> = (props) => (
  <Typography variant="h5" fontWeight={400} {...props} />
);

export const SubjectDetails: React.FC<TypographyProps> = (props) => {
  const theme = useTheme();
  return(
    <Typography
      variant="subtitle2"
      color={theme.palette.neutral.main}
      style={{ whiteSpace: 'pre-line' }}
      {...props}
    />
  );
};

export const Copy = Typography;

export const Detail: React.FC<TypographyProps> = (props) => (
  <Typography variant="caption" component="span" {...props} />
);

export const triangleCharacter = `\u25B2`;
