import React, { PropsWithChildren, useContext } from 'react';
import { useMemo } from 'react';
import { Api } from '../api/backend-client/Api';
export { Api } from '../api/backend-client/Api';

export const BackendContext = React.createContext<Api>(null as any);

export function useBackendApi() {
  return useContext(BackendContext);
}

export const BackendProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const api = useMemo(() => {
    return new Api();
  }, []);

  return (
    <BackendContext.Provider value={api}>{children}</BackendContext.Provider>
  );
};
