import {
  AppointmentDto,
  EmailActivityDto,
} from '../api/backend-client/data-contracts';
import { parseDate } from './time';

export function formatNumberToOrdinal(number: number) {
  if (number === 0) {
    return number;
  }

  const specialCases: { [key: number]: string } = {
    11: 'th',
    12: 'th',
    13: 'th',
  }; // Handling special cases

  const lastDigit = number % 10;
  const suffix =
    specialCases[number] ||
    (lastDigit === 1
      ? 'st'
      : lastDigit === 2
      ? 'nd'
      : lastDigit === 3
      ? 'rd'
      : 'th');

  return number + suffix;
}

export function formatPercent(percent: number): string {
  return `${Math.round(percent)}%`;
}

export function formatDegree(degrees: number) {
  return degrees + '°';
}

export function formatPercentile(percentile: number): string {
  if (percentile % 10 === 1) {
    return percentile + 'st';
  }
  if (percentile % 10 === 2) {
    return percentile + 'nd';
  }
  if (percentile % 10 === 3) {
    return percentile + 'rd';
  }
  return percentile + 'th';
}

export function formatList(list: string[]): string {
  if (list.length === 1) {
    return `${list[0]}`;
  }
  if (list.length === 2) {
    return `${list[0]} and ${list[1]}`;
  }

  const items = list.map((item, i) => {
    if (i === list.length - 1) {
      return `and ${item}`;
    }
    return `${item}`;
  });

  return items.join(', ');
}

export function formatNumberedDate(date: Date) {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export function formatNumberedDateWithoutYear(date: Date) {
  return `${date.getMonth() + 1}/${date.getDate()}`;
}

export function formatNumberedDateWithWeekday(date: Date) {
  return date.toLocaleString('en-US', {
    weekday: 'long',
    month: 'numeric',
    day: 'numeric',
  });
}

export function formatDateWithLongMonth(date: Date) {
  return date.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

export function formatTime(date: Date) {
  return date.toLocaleTimeString([], { timeStyle: 'short' });
}

export function formatAppointmentType(type: AppointmentDto['appointmentType']) {
  switch (type) {
    case 'inPerson':
      return 'In-Person Session';
    case 'telehealth':
      return 'Telehealth Session';
    case undefined:
      return 'Session';
  }
}

export function formatAppointmentTypeWithArticle(
  type: AppointmentDto['appointmentType'],
) {
  switch (type) {
    case 'inPerson':
      return 'an in-Person session';
    case 'telehealth':
      return 'a telehealth session';
    case undefined:
      return 'a session';
  }
}

export function formatAppointment(appointment: AppointmentDto) {
  const startDate = parseDate(appointment.startTime || '');
  let type: string | undefined;

  type = formatAppointmentType(appointment.appointmentType);

  return `${type} (${formatNumberedDate(startDate)})`;
}

export function formatEmailType(type: EmailActivityDto['type']): string {
  switch (type) {
    case 'form_appointment_email':
    case 'consent_form_appointment_email':
    case 'no_form_appointment_email':
      return 'session invite email';
    case 'report_email':
      return 'session report email';
    case 'appointment_cancellation_email':
      return 'session cancellation email';
    case 'consent_email':
      return 'consent email';
    case 'hep_email':
      return 'HEP email';
    case 'unknown':
      return 'email';
  }
}

export function capitalizeFirstLetter(input: string): string {
  if (input.length === 0) {
    return input;
  }

  return input.charAt(0).toUpperCase() + input.slice(1);
}
