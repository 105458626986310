import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useBackendApi } from '../lib/backend';
import {
  PatientDto,
  RtmPatientsUpdateReq,
} from './backend-client/data-contracts';
import { useIdToken } from './firebase';
import { unwrapResponse } from './util';

export function usePatient(patientId?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['patient', patientId], {
    queryFn: () =>
      api.patientControllerGetPatient(patientId!).then(unwrapResponse),
    enabled: !!idToken.data && !!patientId,
  });
}

export function usePatients(ids: string[] | undefined) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['patients', ids], {
    queryFn: () =>
      api.patientControllerGetPatients({ ids }).then(unwrapResponse),
    enabled: !!idToken.data && ids !== undefined,
  });
}

export function usePatientsForTeams(teamIds?: string[]) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['patients', teamIds], {
    queryFn: () =>
      api
        .patientControllerGetPatientsForTeams({ teamIds: teamIds! })
        .then(unwrapResponse),
    enabled: !!idToken.data && !!teamIds,
  });
}

export function useLoginPatient() {
  const api = useBackendApi();

  return useMutation(async (userAgent: string) => {
    const response = await api.patientControllerLogin({ userAgent });
    if (response.status >= 300) {
      throw new Error(response.statusText);
    }
    return response.data;
  });
}

export function useCreatePatient() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async () => {
      const response = await api.patientControllerCreatePatient();
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('patients');
        await queryClient.invalidateQueries('whoami');
      },
    },
  );
}

export function useUpdatePatient() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: PatientDto) => {
      const response = await api.patientControllerUpdatePatient(body);
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('patient');
        queryClient.invalidateQueries('whoami');
      },
    },
  );
}

export function useUpdateRtmPatients() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: RtmPatientsUpdateReq) => {
      const response = await api.patientControllerUpdateRtmPatients(body);
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('episodes');
        queryClient.invalidateQueries('billableCodes');
        queryClient.invalidateQueries('clinicianPatients');
      },
    },
  );
}
