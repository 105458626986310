import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface EmptyStateProps {
  title: string;
  subtitle?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ title, subtitle }) => (
  <Stack
    width="100%"
    height="100%"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    p={3}
  >
    <Typography variant="h4">{title}</Typography>
    <Typography variant="body1">{subtitle}</Typography>
  </Stack>
);
