import React from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { ContentMain, Page } from './Page';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';

const CenteringBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading: React.FC<CircularProgressProps> = ({ ...props }) => (
  <CenteringBox p={1}>
    <CircularProgress {...props} />
  </CenteringBox>
);

export const LoadingSkeleton: React.FC<SkeletonProps> = ({ ...props }) => (
  <Skeleton
    variant="rounded"
    animation="wave"
    width="100%"
    height="100%"
    {...props}
  />
);

export const LoadingPage: React.FC = () => (
  <Page title="Loading">
    <ContentMain>
      <Loading />
    </ContentMain>
  </Page>
);

export const LoadingProgress: React.FC<{ fraction: number }> = ({
  fraction,
}) => (
  <CenteringBox p={1}>
    <CircularProgress variant="determinate" value={fraction * 100} />
  </CenteringBox>
);

export const LoadingProgressPage: React.FC<{ fraction: number }> = ({
  fraction,
}) => (
  <Page title="Loading">
    <ContentMain>
      <LoadingProgress fraction={fraction} />
    </ContentMain>
  </Page>
);

export const SuspenseLoading: React.FC<React.PropsWithChildren> = ({
  children,
}) => <React.Suspense fallback={<Loading />}>{children}</React.Suspense>;

const RelativeBox = styled(Box)`
  position: relative;
`;

export const Backdrop = styled(Box)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const LoadingOverlay: React.FC<
  React.PropsWithChildren<{ loading: boolean }> & BoxProps
> = ({ loading, children, ...boxProps }) => (
  <RelativeBox {...boxProps}>
    {children}
    {loading ? (
      <Backdrop>
        <Loading />
      </Backdrop>
    ) : null}
  </RelativeBox>
);
