import { useQuery } from 'react-query';
import { useBackendApi } from '../lib/backend';
import { Api } from './backend-client/Api';
import { useIdToken } from './firebase';
import { unwrapResponse } from './util';

export type PrescriptionQuery = Parameters<
  Api['prescriptionControllerListPrescriptions']
>[0];

export function usePrescriptions(query: PrescriptionQuery) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['prescriptions', query], {
    queryFn: () =>
      api.prescriptionControllerListPrescriptions(query).then(unwrapResponse),
    enabled: !!idToken.data && !!query,
  });
}

export function usePrescription(id?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['prescriptions', id], {
    queryFn: () =>
      api.prescriptionControllerGetPrescription(id!).then(unwrapResponse),
    enabled: !!idToken.data && !!id,
  });
}
