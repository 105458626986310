import { useBackendApi } from '../lib/backend';
import { useIdToken } from './firebase';
import { unwrapResponse } from './util';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from './backend-client/Api';

export function useCalendlyTestSync() {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['calendly', 'sync'], {
    queryFn: () =>
      api.calendlyControllerTestSyncCalendly().then(unwrapResponse),
    enabled: !!idToken.data,
  });
}

type CalendlySyncQuery = Parameters<Api['calendlyControllerDoSyncCalendly']>[0];

export function useCalendlySync() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (query?: CalendlySyncQuery) => {
      const response = await api.calendlyControllerDoSyncCalendly(query);

      if (response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('calendly');
      },
    },
  );
}
