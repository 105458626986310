import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useWhoAmI } from '../api/clinician';
import { RequireFirebaseAuth } from '../api/firebase';
import { Page } from '../features/chrome/Page';
import { buildSignupRoute } from '../routes';

export const RequireSignup: React.FC<PropsWithChildren> = ({ children }) => {
  const whoAmIQ = useWhoAmI();
  const location = useLocation();

  if (whoAmIQ.isLoading) {
    return <Page title="Loading" />;
  }

  const { data: whoAmI } = whoAmIQ;

  if (!whoAmI || !whoAmI.patient?.uid) {
    return (
      <Navigate
        to={buildSignupRoute()}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  return children as any;
};

export const RequireVifiveAuth: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <RequireFirebaseAuth>
      <RequireSignup>{children}</RequireSignup>
    </RequireFirebaseAuth>
  );
};
