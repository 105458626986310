import { useEffect } from 'react';

export function useSubscribe<T>(
  eventName: string | undefined,
  handler: (data: T) => void,
) {
  useEffect(() => {
    if (!eventName) {
      return;
    }
    const effectEventName = eventName;

    function handleStoredData() {
      consumeStoredArray(effectEventName, handler);
    }

    handleStoredData();
    document.addEventListener(effectEventName, handleStoredData);
    return () =>
      document.removeEventListener(effectEventName, handleStoredData);
  }, [eventName]);
}

export function usePublish<T>(eventName: string): (payload: T) => void {
  return function storeAndNotify(payload: T) {
    storeInArray(eventName, payload);
    const event = new Event(eventName);
    document.dispatchEvent(event);
  };
}

function storeInArray<T>(key: string, payload: T) {
  try {
    const existingDataStr = localStorage.getItem(key);
    const existingData: T[] = existingDataStr
      ? JSON.parse(existingDataStr)
      : [];
    existingData.push(payload);
    localStorage.setItem(key, JSON.stringify(existingData));
  } catch (e) {
    console.error(e);
  }
}

function consumeStoredArray<T>(key: string, handleItem: (payload: T) => void) {
  const existingDataStr = localStorage.getItem(key);
  if (!existingDataStr) {
    return;
  }
  try {
    const existingData: T[] = JSON.parse(existingDataStr);
    existingData.forEach(handleItem);
    localStorage.removeItem(key);
  } catch (e) {
    console.error(e);
  }
}
