import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useBackendApi } from '../lib/backend';
import { Api } from './backend-client/Api';
import {
  AppointmentDto,
  CreateAppointmentDto,
  CreateConnectionDto,
} from './backend-client/data-contracts';
import { useIdToken } from './firebase';
import { getJwtApi } from './jwtApi';
import { unwrapResponse } from './util';

export type AppointmentQuery = Parameters<
  Api['appointmentControllerListAppointments']
>[0];

export function useAppointment(appointmentId?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['appointment', appointmentId], {
    queryFn: () =>
      api
        .appointmentControllerGetAppointment(appointmentId!)
        .then(unwrapResponse),
    enabled: !!idToken.data && !!appointmentId,
  });
}

export function useAppointments(appointmentIds?: string[]) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['appointment', appointmentIds], {
    queryFn: async () => {
      const appointments = await api
        .appointmentControllerGetAppointments({
          appointmentIds: appointmentIds!,
        })
        .then(unwrapResponse);
      return visibleAppointments(appointments);
    },
    enabled: !!idToken.data && !!appointmentIds,
  });
}

export function useAppointmentList(query: AppointmentQuery) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['appointment', query], {
    queryFn: async () => {
      const appointments = await api
        .appointmentControllerListAppointments(query)
        .then(unwrapResponse);
      return visibleAppointments(appointments);
    },
    enabled: !!idToken.data && !!query,
  });
}

export function useCreateAppointment() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: { appointment: CreateAppointmentDto }) => {
      const response = await api.appointmentControllerCreateAppointment(
        body.appointment,
      );

      if (response.status >= 300) {
        throw new Error(response.statusText);
      }

      return await response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('appointment');
      },
    },
  );
}

export function useCreateAppointmentWorkflow() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (appointment: CreateAppointmentDto) => {
      const response =
        await api.appointmentControllerRunCreateAppointmentWorkflow(
          appointment,
        );

      if (response.status >= 300) {
        throw new Error(response.statusText);
      }

      return await response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('appointment');
      },
    },
  );
}

export function useDeleteAppointment() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (id: string) => {
      const response = await api.appointmentControllerDeleteAppointment(id);

      if (response.status >= 300) {
        throw new Error(response.statusText);
      }

      return await response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('appointment');
      },
    },
  );
}

export function useConnectAppointment() {
  const api = useBackendApi();

  return useMutation(
    async ({
      appointmentId,
      data,
    }: {
      appointmentId: string;
      data: CreateConnectionDto;
    }) => {
      const response = await api.appointmentControllerCreateConnection(
        appointmentId,
        data,
      );

      if (response.status >= 300) {
        throw new Error(response.statusText);
      }

      return await response.data;
    },
  );
}

export function useConnectAppointmentWithToken(
  token: string,
  userAgent: string,
) {
  const jwtApi = getJwtApi(token);

  return useQuery(['connection', token], {
    queryFn: async () => {
      const res =
        await jwtApi.appointmentControllerCheckConsentAndCreateConnectionWithToken(
          { userAgent },
        );
      return res.data;
    },
  });
}

export function useAppointmentEmail() {
  const api = useBackendApi();

  return useMutation(async (body: AppointmentDto) => {
    const response = await api.appointmentControllerSendAppointmentEmail(body);

    if (response.status >= 300) {
      throw new Error(response.statusText);
    }

    return await response.data;
  });
}

export function usePatientList(clinicianId?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['patient', 'appointment', clinicianId], {
    queryFn: () =>
      api
        .appointmentControllerGetPatientsInClinicianAppointments({
          clinicianId: clinicianId!,
        })
        .then(unwrapResponse),
    enabled: !!idToken.data && !!clinicianId,
  });
}

export function useAppointmentSummary(appointmentId?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['appointment-summary', appointmentId], {
    queryFn: () =>
      api
        .summaryControllerGetSessionSummaries({
          appointment: appointmentId!,
        })
        .then(unwrapResponse),
    enabled: !!idToken.data && !!appointmentId,
  });
}

export function useGenerateAppointmentTokenUrl(appointmentId?: string) {
  const api = useBackendApi();

  return useMutation(async (appointmentId: string) => {
    const response = await api.appointmentControllerGenerateAppointmentTokenUrl(
      appointmentId,
    );

    if (response.status >= 300) {
      throw new Error(response.statusText);
    }

    return await response.data;
  });
}

function visibleAppointments(appointments: AppointmentDto[]): AppointmentDto[] {
  return appointments.filter((appointment) => !(appointment.isVoided || false));
}
