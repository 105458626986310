import { Event } from '@mui/icons-material';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useRoutePath } from './location';

export type Event = SelfTestEvent;

// prefix: vf_web_st_
export enum SelfTestEvent {
  intro_continue_click = 'vf_web_st_intro_continue_click',
  video_continue_click = 'vf_web_st_video_continue_click',
  setup_continue_click = 'vf_web_st_setup_continue_click',
  camera_setup_skip = 'vf_web_st_camera_setup_skip',
  initial_pose_complete = 'vf_web_st_initial_pose_complete',
  exercise_start = 'vf_web_st_exercise_start',
  exercise_complete = 'vf_web_st_exercise_complete',
  report_click = 'vf_web_st_report_click',
  claim_session_click = 'vf_web_st_claim_session_click',
  schedule_session_complete = 'vf_web_st_schedule_session_complete',
  save_later_click = 'vf_web_st_save_later_click',
  signup_complete = 'vf_web_st_signup_complete',
}

export interface EventParams {
  exercise_id?: string;
}

export function logEvent(event: Event, { exercise_id }: EventParams = {}) {
  const params = { exercise_id };
  ReactGA.event(event, {
    ...params,
  });
}

export function usePageviewLogging() {
  const routePath = useRoutePath();
  useEffect(() => {
    if (routePath !== undefined) {
      ReactGA.send({ hitType: 'pageview', path: routePath });
    }
  });
}
