import { useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { HttpResponse } from './backend-client/http-client';

export function unwrapResponse<T>(resp: HttpResponse<T>) {
  if (resp.status >= 400) {
    throw new HttpStatusException(resp);
  }

  return resp.data;
}

class HttpStatusException extends Error {
  constructor(httpResponse: HttpResponse<unknown, unknown>) {
    super(
      `HttpStatusException: ${httpResponse.status} ${httpResponse.statusText}`,
    );
  }
}

export function useQueryMemoMap<T, R>(
  query: UseQueryResult<T>,
  map: (t: T) => R,
): UseQueryResult<R> {
  // @ts-ignore
  return {
    ...query,
    data: useMemo(() => query.data && map(query.data), [query.data]),
  };
}
