import React, { useMemo } from 'react';
import {
  QueryClient,
  QueryObserverIdleResult,
  QueryObserverLoadingErrorResult,
  QueryObserverLoadingResult,
  QueryObserverRefetchErrorResult,
  QueryObserverResult,
  QueryObserverSuccessResult,
  UseQueryResult,
} from 'react-query';

type UseQueryArgs<UseQuery> = UseQuery extends (args: infer A) => any
  ? A
  : never;
type UseQueryData<UseQuery> = UseQuery extends (
  args: any,
) => UseQueryResult<infer TData, any>
  ? TData
  : never;

export type QueryArgsProps<TArgs> = { args: TArgs };

export type QuerySuccess<TData> = QueryObserverSuccessResult<TData, unknown>;
export type QuerySuccessProps<TData> = { query: QuerySuccess<TData> };

export function queryIsSuccess<TData>(
  q: QueryObserverResult<TData, unknown>,
): q is QuerySuccess<TData> {
  return q.isSuccess;
}
// This type has some funny behaviors (TProps expands to include too much), don't use it externally
type QuerySuccessFC<TArgs, TData, TProps = {}> = React.FC<
  React.PropsWithChildren<QueryArgsProps<TArgs> & QuerySuccessProps<TData>> &
    TProps
>;

// Use this instead
export type QuerySuccessFor<UseQuery, TProps = {}> = QuerySuccessFC<
  UseQueryArgs<UseQuery>,
  UseQueryData<UseQuery>,
  TProps
>;

export { QueryClientProvider, useQuery } from 'react-query';

function errorIsResponse(error: unknown): error is Response {
  return error instanceof Response;
}

const twelveHoursInMs = 1000 * 60 * 60 * 12;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: twelveHoursInMs,
      retry(failureCount, error) {
        if (errorIsResponse(error)) {
          if (error.status >= 400 && error.status < 500) {
            return false;
          }
        }
        return failureCount < 3;
      },
    },
  },
});
