import { Auth, getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import React, {
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
  useMemo,
} from 'react';

import { useLocation, Navigate } from 'react-router-dom';
import { AccountResponse } from './backend-client/data-contracts';

import { useQuery } from 'react-query';
import { Api, BackendContext } from '../lib/backend';
import { Buffer } from 'buffer';
import { Page } from '../features/chrome/Page';
import { buildAuthRoute } from '../routes';

// TODO: discuss benefits to serving clientside configuration from the server
const FIREBASE_CONFIG = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

export function useFirebaseApp() {
  return useMemo(() => initializeApp(FIREBASE_CONFIG), []);
}

export function useIdToken() {
  const { authState, auth } = useAuthContext();

  return useQuery(['idToken', authState?.uid], {
    queryFn: async () => {
      const windowToken = (window as any).token as string | undefined;
      if (windowToken) {
        return windowToken;
      }
      if (!auth.currentUser) {
        return null;
      }
      return await auth.currentUser.getIdToken();
    },
  });
}

// This is a subset of the actual "User" type from firebase/auth
interface AuthUser {
  uid: string;
  displayName: string | null;
  email: string | null;
}

type AuthState = AuthUser | null | undefined;

interface AuthContext {
  authState: AuthState;
  auth: Auth;
}

export const AuthContext = React.createContext<AuthContext>(null!);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const app = useFirebaseApp();

  const [authState, setAuthState] = useState<AuthState>(undefined);

  const auth = useMemo(() => getAuth(app), [app]);

  useEffect(() => {
    let tokenLoop: number | undefined;
    // @ts-ignore
    if (window.expectToken) {
      const tokenLoop = setInterval(() => {
        // @ts-ignore
        if (window.token) {
          clearInterval(tokenLoop);
          // @ts-ignore
          const idData = decodeIdToken(window.token);

          setAuthState({
            uid: idData.user_id,
            displayName: idData.name,
            email: idData.email,
          });
        }
      }, 100);
    }

    return () => {
      if (tokenLoop) {
        clearInterval(tokenLoop);
      }
    };
  }, [auth]);

  // setAuthState is actually called with the full `User` type
  useEffect(() => auth.onAuthStateChanged(setAuthState), [auth]);

  return (
    <AuthContext.Provider value={{ auth, authState }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthBackendProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { data: idToken } = useIdToken();
  const { authState } = useAuthContext();

  const api = useMemo(() => {
    const api = new Api({
      baseApiParams: {
        headers: idToken
          ? {
              Authorization: `Bearer ${idToken}`,
            }
          : undefined,
      },
    });
    const login = async () => {
      await api.patientControllerLogin({ userAgent: navigator.userAgent });
    };

    if (idToken) {
      login();
    }
    return api;
  }, [idToken, authState]);

  return (
    <BackendContext.Provider value={api}>{children}</BackendContext.Provider>
  );
};

export function useAuthContext(): AuthContext {
  return useContext(AuthContext);
}

export const RequireFirebaseAuth: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { authState } = useAuthContext();
  const idToken = useIdToken();
  const location = useLocation();

  if (authState === undefined || idToken.isLoading) {
    return <Page title="Loading" />;
  }

  if (authState === null && !idToken.data) {
    return (
      <Navigate
        to={buildAuthRoute()}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  return children as any;
};

export function accountShowsAdmin(account: AccountResponse): boolean {
  return !!account.clinician;
}

interface IdToken {
  name: string;
  picture: string;
  iss: string;
  aud: string;
  auth_time: number;
  user_id: string;
  sub: string;
  iat: number;
  exp: number;
  email: string;
  email_verified: boolean;
  firebase: Firebase;
}

interface Firebase {
  identities: Record<string, string[]>;
  sign_in_provider: string;
}

export function decodeIdToken(idToken: string): IdToken {
  const json = Buffer.from(idToken.split('.')[1], 'base64');

  return JSON.parse(json.toString());
}
