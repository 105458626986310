import React from 'react';
import { PatientDto } from '../../api/backend-client/data-contracts';
import { buildPatientsRoute } from '../../routes';
import { Breadcrumb, BreadCrumbs } from '../chrome/Breadcrumb';

export function patientBreadcrumbs(
  patientId?: string,
  patient?: PatientDto,
): Array<Breadcrumb> {
  return patientId
    ? [
        { path: '/patients', name: 'Patients' },
        ...(patient !== undefined
          ? [
              {
                path: buildPatientsRoute(patient.id),
                name: patient.patientName || patient.patientEmail,
              },
            ]
          : []),
      ]
    : [];
}

export const PatientBreadcrumbs: React.FC<{
  patientId: string;
  patient?: PatientDto;
}> = ({ patientId, patient }) => {
  return <BreadCrumbs crumbs={patientBreadcrumbs(patientId, patient)!} />;
};
