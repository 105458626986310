import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useBackendApi } from '../lib/backend';
import { ClinicianDto } from './backend-client/data-contracts';
import { useIdToken } from './firebase';
import { unwrapResponse } from './util';

export function useWhoAmI() {
  const idToken = useIdToken();
  const api = useBackendApi();
  const ONE_HOUR = 1000 * 60 * 60; // whoami is stale after one hour

  return useQuery(['whoami', idToken.data], {
    queryFn: () => api.accountControllerGetWhoami().then(unwrapResponse),
    enabled: !!idToken.data,
    staleTime: ONE_HOUR,
  });
}

export function useClinician(clinicianId?: string) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['clinician', clinicianId], {
    queryFn: () =>
      api.clinicianControllerGetClinician(clinicianId!).then(unwrapResponse),
    enabled: !!idToken.data && !!clinicianId,
  });
}

export function useClinicians(ids: string[] | undefined) {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['clinician', ids], {
    queryFn: () =>
      api.clinicianControllerGetClinicians({ ids }).then(unwrapResponse),
    enabled: !!idToken.data && ids !== undefined,
  });
}

export function useMyPatients() {
  const idToken = useIdToken();
  const api = useBackendApi();

  return useQuery(['clinicianPatients'], {
    queryFn: () => api.clinicianControllerGetMyPatients().then(unwrapResponse),
    enabled: !!idToken.data,
  });
}

export function useBecomeClinician() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (_) => {
      const response = await api.clinicianControllerGetBecomeClinician();

      if (response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('whoami');
      },
    },
  );
}

export function useUpdateClinician() {
  const queryClient = useQueryClient();
  const api = useBackendApi();

  return useMutation(
    async (body: ClinicianDto) => {
      const response = await api.clinicianControllerUpdateClinician(body);
      if (response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clinician');
      },
    },
  );
}
