// Use the following editor to customize the site theme
// https://zenoo.github.io/mui-theme-creator/
import { createTheme, Theme } from '@mui/material/styles';
import { createContext } from 'react';

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    pain: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    pain: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    pain: true;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    borderRadius: {
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
  }
  interface ThemeOptions {
    borderRadius: {
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
  }
  interface Palette {
    neutral: Palette['primary'];
    pain: Palette['primary'];
    exercise: {
      right?: string;
      left?: string;
    };
    severity: {
      low?: string;
      moderate?: string;
      high?: string;
    };
    chart: {
      primary?: string;
      secondary?: string;
      neutral?: string;
    };
    border: {
      primary?: string,
      default?: string,
      secondary?: string,
      tertiary?: string,
      quaternerary?: string,
    };
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    pain?: PaletteOptions['primary'];
    exercise: {
      right?: string;
      left?: string;
    };
    severity: {
      low?: string;
      moderate?: string;
      high?: string;
    };
    chart: {
      primary?: string;
      secondary?: string;
      neutral?: string;
    };
    border: {
      primary?: string,
      default?: string,
      secondary?: string,
      tertiary?: string,
      quaternerary?: string,
    };

  }
  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
  interface TypeBackground {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternerary: string;
    highlight: string;
    panel: string;
    lighter?: string;
  }
}

export enum ThemeType {
  Light = 'light',
  Dark = 'dark',
}

export const darkTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#58635B #313932',
          '&::-webkit-scrollbar': {
            width: '15px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#58635B',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#313932',
            borderRadius: '10px',
            border: '3px solid transparent',
            backgroundClip: 'content-box',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            '&:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px #313932 inset !important`,
              WebkitTextFillColor: 'default',
            },
          },
        },
      },
    },
  },
  palette: {
    mode: ThemeType.Dark,
    primary: {
      main: '#E9744F',
      contrastText: '#FFFBEE',
    },
    secondary: {
      main: '#9F7DE1',
      contrastText: '#FFFBEE',
    },
    text: {
      primary: '#FFFBEE',
    },
    background: {
      primary: '#313932',
      default: '#313932',
      secondary: '#3B433C',
      tertiary: '#464D46',
      quaternerary: '#58635B',
      highlight: '#83958B',
      panel: '#2A2A2A',
      paper: '#3B433C',
      lighter: '#464D46',
    },
    error: {
      main: '#AE213A',
    },
    success: {
      main: '#7DE180',
    },
    info: {
      main: '#464D46',
      contrastText: '#FFFBEE',
    },
    warning: {
      main: '#FAC805',
    },
    pain: {
      main: '#4F57E9',
      contrastText: '#FFFBEE',
    },
    exercise: {
      right: '#E05C5C',
      left: '#4F57E9',
    },
    severity: {
      low: '#40AA5E',
      moderate: '#fae105',
      high: '#f5aa07',
    },
    neutral: {
      lighter: '#FFFBEE',
      light: '#e7e7e8',
      main: '#CCCCCC',
      darker: '#000000',
      contrastText: '#000',
    },
    chart: {
      primary: '#03B4A8',
      secondary: '#E1B543',
      neutral: '#83958B',
    },
    border: {
      primary: '#FFFFFF00',
      default: '#F5F5F5',
      secondary: '#E0E0E0',
      tertiary: '#D3D3D3',
      quaternerary: '#C4C4C4',
    },
  },
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    fontSize: 16,
    allVariants: {
      color: '#FFFBEE',
    },
    h1: {
      fontSize: '2.5rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '0.9375rem',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    },
  },
  spacing: [0, 4, 8, 16, 24, 32, 40, 48],
  shape: {
    borderRadius: 4,
  },
  borderRadius: {
    // These multiply the base shape.borderRadius
    sm: 0.5,
    md: 1,
    lg: 2,
    xl: 4,
  },
});

export const lightTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#E0E0E0 #FFFFFF',
          '&::-webkit-scrollbar': {
            width: '15px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#FFFFFF',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#FFFFFF',
            borderRadius: '10px',
            border: '3px solid transparent',
            backgroundClip: 'content-box',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            '&:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px #FFFFFF inset !important`,
              WebkitTextFillColor: 'default',
            },
          },
        },
      },
    },
  },
  palette: {
    mode: ThemeType.Light,
    primary: {
      main: '#E9744F',
      contrastText: '#212121',
    },
    secondary: {
      main: '#9F7DE1',
      contrastText: '#212121',
    },
    text: {
      primary: '#212121',
    },
    background: {
      primary: '#FFFFFF',
      default: '#F5F5F5',
      secondary: '#FFFFFF',
      tertiary: '#FFFFFF',
      quaternerary: '#464D4660',
      highlight: '#A6A6A6',
      panel: '#E0E0E0',
      paper: '#FFFFFF',
      lighter: '#F5F5F5',
    },
    error: {
      main: '#AE213A',
    },
    success: {
      main: '#7DE180',
    },
    info: {
      main: '#A6A6A6',
      contrastText: '#212121',
    },
    warning: {
      main: '#FAC805',
    },
    pain: {
      main: '#4F57E9',
      contrastText: '#212121',
    },
    exercise: {
      right: '#E05C5C',
      left: '#4F57E9',
    },
    severity: {
      low: '#40AA5E',
      moderate: '#fae105',
      high: '#f5aa07',
    },
    neutral: {
      lighter: '#FFFFFF',
      light: '#F5F5F5',
      main: '#212121',
      darker: '#212121',
      contrastText: '#000',
    },
    chart: {
      primary: '#03B4A8',
      secondary: '#E1B543',
      neutral: '#A6A6A6',
    },
    border: {
      primary: '#464D4660',
      default: '#F5F5F5',
      secondary: '#E0E0E0',
      tertiary: '#D3D3D3',
      quaternerary: '#C4C4C4',
    },
  },
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    fontSize: 16,
    allVariants: {
      color: '#212121',
    },
    h1: {
      fontSize: '2.5rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '0.9375rem',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    },
  },
  spacing: [0, 4, 8, 16, 24, 32, 40, 48],
  shape: {
    borderRadius: 4,
  },
  borderRadius: {
    // These multiply the base shape.borderRadius
    sm: 0.5,
    md: 1,
    lg: 2,
    xl: 4,
  },
});

export function getBaseBorderRadius(theme: Theme) {
  return theme.shape.borderRadius as number;
}

export const ThemeSelectorContext = createContext<[ThemeType, React.Dispatch<React.SetStateAction<ThemeType>>]>([ThemeType.Dark, () => {}]);

export default darkTheme;