import React from 'react';

import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Loading } from './Loading';
import { PageProps } from './Page';

export const FullscreenContainer = styled('main')(
  ({ theme }) => `
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.background.primary};
`,
);

const StandaloneMain = styled('main')(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const StandalonePage: React.FC<React.PropsWithChildren<PageProps>> = ({
  title,
  children,
}) => (
  <FullscreenContainer>
    <CssBaseline />
    <StandaloneMain>{children}</StandaloneMain>
  </FullscreenContainer>
);

export const SuspenseStandalonePage: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <React.Suspense
    fallback={
      <StandalonePage title="-">
        <Loading />
      </StandalonePage>
    }
  >
    {children}
  </React.Suspense>
);
